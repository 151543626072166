import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },
  {
    path: '/customer',
    component: Layout,
    children: [
      {
        path: 'uavTaskList',
        component: () => import('@/views/uav/task/index'), //无人机任务管理
        name: 'UavTaskList',
        meta: { title: '无人机任务列表', noCache: true }
      },
      {
        path: 'uavTaskView',
        component: () => import('@/views/uav/taskView/index'), //无人机巡田结果
        name: 'UavTaskView',
        meta: { title: '无人机巡田结果', noCache: true }
      }
      // {
      //   path: "uavTaskResult",
      //   component: () => import("@/views/uav/task/result"), //无人机巡田结果
      //   name: "UavTaskResult",
      //   meta: { title: "无人机巡田结果", noCache: true },
      // },
      // {
      //   path: "uavTest",
      //   component: () => import("@/views/uav/test/index"), //无人机巡田结果
      //   name: "uavTest",
      //   meta: { title: "无人机巡田结果", noCache: true },
      // },
    ]
  },
  {
    path: '/precision',
    component: Layout,
    children: [
      {
        path: 'yieldmap/index',
        component: () => import('@/views/precision/yield/index'), //产量地图列表
        name: 'YieldMapIndex',
        meta: { title: '产量地图列表', noCache: true }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/landList',
    children: [
      {
        path: '/landList',
        component: () => import('@/views/land/index'), //地块管理
        name: 'landList',
        meta: { title: '地块列表', noCache: true }
      },
      {
        path: '/landDetails',
        component: () => import('@/views/land/landDetail'), //地块详情
        name: 'landDetails',
        meta: { title: '地块详情', noCache: true }
      },
      //农机管理
      {
        path: '/machineManage',
        component: () => import('@/views/machineManage/index'),
        name: 'machineManage',
        meta: { title: '农机管理', noCache: true }
      },
      //农机申请列表
      {
        path: '/machineList',
        component: () => import('@/views/machineManage/list'),
        name: 'machineList',
        meta: { title: '农机申请列表', noCache: true }
      },
      {
        path: '/dzwl',
        component: () => import('@/views/njgl/dzwl'), //农机管理-电子围栏
        name: 'dzwl',
        meta: { title: '电子围栏', noCache: true }
      },
      {
        path: '/dzwlBind',
        component: () => import('@/views/njgl/dzwlBind'), //农机管理-电子围栏绑定
        name: 'dzwlBind',
        meta: { title: '电子围栏绑定', noCache: true }
      },
      {
        path: '/njgj',
        component: () => import('@/views/njgl/njgj'), //农机管理-农机轨迹
        name: 'njgj',
        meta: { title: '农机轨迹', noCache: true }
      },
      {
        path: '/njgl',
        component: () => import('@/views/njgl/njgl'), //农机管理-设备管理
        name: 'njgl',
        meta: { title: '设备管理', noCache: true }
      },
      {
        path: '/operationRecord',
        component: () => import('@/views/operationRecord/index'), //农机管理-操作记录
        name: 'operationRecord',
        meta: { title: '操作记录', noCache: true }
      },
      {
        path: '/situation1',
        component: () => import('@/views/situation/index1'), //四情
        name: 'situation',
        meta: { title: '四情', noCache: true }
      },
      {
        path: '/deviceManage',
        component: () => import('@/views/deviceManage/index'), //平移设备管理
        name: 'deviceManage',
        meta: { title: '平移设备管理', noCache: true }
      },
      {
        path: '/sbgl',
        component: () => import('@/views/njgl/sbgl'), //农机管理-设备管理
        name: 'sbgl',
        meta: { title: '设备管理', noCache: true }
      },
      {
        path: '/pointerManage',
        component: () => import('@/views/deviceManage/pointerManage'), //指针设备管理
        name: 'pointerManage',
        meta: { title: '指针设备管理', noCache: true }
      },
      {
        path: '/deviceList',
        component: () => import('@/views/deviceList/index'),
        name: 'deviceList',
        meta: { title: '物联网设备监管', noCache: true }
      },
      {
        path: '/fixedManage',
        component: () => import('@/views/deviceManage/fixedManage'), //固定设备管理
        name: 'fixedManage',
        meta: { title: '固定喷灌', noCache: true }
      },
      {
        path: '/sjManage',
        component: () => import('@/views/deviceManage/sjManage'), //水井设备管理
        name: 'sjManage',
        meta: { title: '水井管理', noCache: true }
      },
      {
        path: '/hydropower',
        component: () => import('@/views/hydropower/index'),
        name: 'hydropower',
        meta: { title: '用水统计报表', noCache: true }
      },
      {
        path: '/sjggUser',
        component: () => import('@/views/sjggUser/index'), // 水价改革用户
        name: 'sjggUser',
        meta: { title: '用户水卡管理', noCache: true }
      },
      {
        path: '/cardBalance',
        component: () => import('@/views/cardBalance/index'), //用卡余额
        name: 'cardBalance',
        meta: { title: '用卡余额统计', noCache: true }
      },
      {
        path: '/cardBalanceDetail',
        component: () => import('@/views/cardBalance/detail'), //用卡余额详情
        name: 'cardBalanceDetail',
        meta: { title: '明细', noCache: true }
      },
      {
        path: '/pumpManage',
        component: () => import('@/views/deviceManage/pumpManage'), //泵站设备管理
        name: 'pumpManage',
        meta: { title: '泵站', noCache: true }
      },
      //文章管理
      {
        path: '/articleManage',
        component: () => import('@/views/articleManage/index'),
        name: 'articleManage',
        meta: { title: '文章管理', noCache: true }
      },
      {
        path: '/business',
        component: () => import('@/views/businessManage/business'),
        name: 'business',
        meta: { title: '企业管理', noCache: true }
      },
      {
        path: '/role',
        component: () => import('@/views/businessManage/role'),
        name: 'role',
        meta: { title: '角色管理', noCache: true }
      },
      {
        path: '/custom',
        component: () => import('@/views/businessManage/custom'),
        name: 'custom',
        meta: { title: '用户管理', noCache: true }
      },
      {
        path: '/maintenanceManage',
        component: () => import('@/views/maintenanceManage/index'),
        name: 'maintenanceManage',
        meta: { title: '服务器运维', noCache: true }
      },
      {
        path: '/deviceList1',
        component: () => import('@/views/deviceList/index1'),
        name: 'deviceList1',
        meta: { title: '物联网设备', noCache: true }
      },
      {
        path: '/stockManage1',
        component: () => import('@/views/stockManage1/index'),
        name: 'stockManage1',
        meta: { title: '种子使用分析', noCache: true }
      },
      {
        path: '/stockManage2',
        component: () => import('@/views/stockManage2/index'),
        name: 'stockManage2',
        meta: { title: '农药使用分析', noCache: true }
      },
      {
        path: '/stockManage3',
        component: () => import('@/views/stockManage3/index'),
        name: 'stockManage3',
        meta: { title: '化肥使用分析', noCache: true }
      },
      {
        path: '/stockManage4',
        component: () => import('@/views/stockManage4/index'),
        name: 'stockManage4',
        meta: { title: '地块使用指导', noCache: true }
      },
      {
        path: '/szsw7',
        component: () => import('@/views/szsw/szsw7'),
        name: 'szsw7',
        meta: { title: '环境监测数据', noCache: true }
      },
      {
        path: '/szsw3',
        component: () => import('@/views/szsw/szsw3'),
        name: 'szsw3',
        meta: { title: '当前水渠水位', noCache: true }
      },
      {
        path: '/szsw4',
        component: () => import('@/views/szsw/szsw4'),
        name: 'szsw4',
        meta: { title: '当前区域设备状态', noCache: true }
      },
      {
        path: '/temporaryWork',
        component: () => import('@/views/plantingPlant/temporaryWork'), //临时任务
        name: 'temporaryWork',
        meta: { title: '临时任务管理', noCache: true }
      },
      //种植方案管理
      {
        path: '/plantingPlant',
        component: () => import('@/views/plantingPlant/index'),
        name: 'plantingPlant',
        meta: { title: '种植方案', noCache: true }
      },
      //种植方案管理
      {
        path: '/farming',
        component: () => import('@/views/plantingPlant/farming'),
        name: 'farming',
        meta: { title: '种植方案-农事详情', noCache: true }
      },
      //种植方案管理-关联地块
      {
        path: '/relatedPlots',
        component: () => import('@/views/plantingPlant/relatedPlots'),
        name: 'relatedPlots',
        meta: { title: '关联地块', noCache: true }
      },
      //种植方案管理-农事
      {
        path: '/land',
        component: () => import('@/views/plantingPlant/land'),
        name: 'land',
        meta: { title: '种植方案-关联地块', noCache: true }
      },
      //种植方案管理-农事
      {
        path: '/homework',
        component: () => import('@/views/plantingPlant/homework'),
        name: 'homework',
        meta: { title: '任务统计分析', noCache: true }
      },
      //种植方案管理-农事
      {
        path: '/farmWorkList',
        component: () => import('@/views/farmWork/index'),
        name: 'farmWorkList',
        meta: { title: '农事记录', noCache: true }
      },
      //种植方案管理-作物产量
      {
        path: '/cropYield',
        component: () => import('@/views/cropYield/index'),
        name: 'cropYield',
        meta: { title: '作物产量', noCache: true }
      },
      {
        path: '/cropCategory',
        component: () => import('@/views/cropCategory/index'),
        name: 'cropCategory',
        meta: { title: '作物品种管理', noCache: true }
      },
      {
        path: '/cropSubCategory',
        component: () => import('@/views/cropCategory/category'),
        name: 'cropSubCategory',
        meta: { title: '作物品种', noCache: true }
      },
      {
        path: '/pest1',
        component: () => import('@/views/insectAttack/pest1'),
        name: 'pest1',
        meta: { title: '虫害知识库', noCache: true }
      },
      {
        path: '/pestRepair2',
        component: () => import('@/views/insectAttack/repair2'), //虫情
        name: 'pestRepair2',
        meta: { title: '病虫害消息预警', noCache: true }
      },
      {
        path: '/cqyc',
        component: () => import('@/views/moxing/cqyc'), //虫情预测
        name: 'cqyc',
        meta: { title: '病虫害预测模型', noCache: true }
      },
      {
        path: '/cqfx',
        component: () => import('@/views/moxing/cqfx'), //虫情分析
        name: 'cqfx',
        meta: { title: '主要虫情诊断分析', noCache: true }
      },
      {
        path: '/binghai',
        component: () => import('@/views/moxing/binghai'), //病害模型
        name: 'binghai',
        meta: { title: '病害模型', noCache: true }
      },
      {
        path: '/cropManage',
        component: () => import('@/views/cropManage/index'), //作物数字模型
        name: 'cropManage',
        meta: { title: '作物数字模型', noCache: true }
      },
      {
        path: '/generateScheme',
        component: () => import('@/views/generateScheme/index'), // 自动生成方案
        name: 'generateScheme',
        meta: { title: '自动生成方案', noCache: true }
      },
      {
        path: '/landMessage',
        component: () => import('@/views/message/landMessage'), // 土壤数据
        name: 'landMessage',
        meta: { title: '土壤数据', noCache: true }
      },
      {
        path: '/soilModel',
        component: () => import('@/views/moxing/soilModel'), //土壤数字模型
        name: 'soilModel',
        meta: { title: '土壤数字模型', noCache: true }
      },
      {
        path: '/szlxt',
        component: () => import('@/views/moxing/szlxt'), //虫情分析
        name: 'szlxt',
        meta: { title: '数字连续体模型', noCache: true }
      },
      {
        path: '/zaihai',
        component: () => import('@/views/moxing/zaihai'), //灾害
        name: 'zaihai',
        meta: { title: '灾害算法模型', noCache: true }
      },
      {
        path: '/zhzl',
        component: () => import('@/views/zhzl/index'), //灾害总览
        name: 'zhzl',
        meta: { title: '灾害总览', noCache: true }
      },
      {
        path: '/soilElementModel',
        component: () => import('@/views/moxing/soilElementModel'), //土壤元素模型
        name: 'soilElementModel',
        meta: { title: '土壤元素模型', noCache: true }
      }
    ]
  }
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:user:edit'],
    children: [
      {
        path: 'role/:userId(\\d+)',
        component: () => import('@/views/system/user/authRole'),
        name: 'AuthRole',
        meta: { title: '分配角色', activeMenu: '/system/user' }
      }
    ]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:role:edit'],
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: () => import('@/views/system/role/authUser'),
        name: 'AuthUser',
        meta: { title: '分配用户', activeMenu: '/system/role' }
      }
    ]
  },
  {
    path: '/system/dict-data',
    component: Layout,
    hidden: true,
    permissions: ['system:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/system/dict/data'),
        name: 'Data',
        meta: { title: '字典数据', activeMenu: '/system/dict' }
      }
    ]
  }
];

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err);
};

export default new Router({
  mode: 'hash', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
});
